<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $store.state.pageTitle }}</v-toolbar-title>
    </template>

    <v-container>
      <div class="text-h5 ml-4 mb-2" style="width: 100%">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ announcement.title }}
      </div>
      <div class="text-small ml-4 mb-2" style="width: 100%">
        {{ announcement.publish_date }}
      </div>
      <v-divider></v-divider>
      <div class="px-4" v-html="announcement.body"></div>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "AnnouncementRead",
  components: { DefaultLayout },

  data: () => ({
    announcement: {},
    loading: null,
  }),

  methods: {
    async getRead() {
      this.loading = true;
      await this.$axios
        .get("announcement/read", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == "success") {
            this.announcement = res.data.data;
          } else {
            this.announcement = [];
          }
        });
    },
  },

  mounted() {
    this.getRead();
    this.setPageTitle("Pengumuman", "mdi-bullhorn");
  },
};
</script>