<template>
  <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      temporary
      right
      :width="width"
      ref="rigthBar"
    >
    <v-card elevation="0" class="border-0">
      <v-card-title>
        <v-btn icon class="mr-2" @click="hide()"><v-icon>mdi-close</v-icon></v-btn>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <slot></slot>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    </v-navigation-drawer>
</template>

<script>
export default {

  props:{
    title: {
      type: String,
      default: '',
    }
  },

  data: () => ({
    drawer: null
  }),

  methods:{
    show(){
      this.drawer = true
    },
    hide(){
      this.drawer = false
    }
  },
  computed: {
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "100%"
        case 'sm': return "80%"
        case 'md': return "50%"
        case 'lg': return "30%"
        case 'xl': return "30%"
      }

      return "100%"
    },
  },
}
</script>